import { getFunnel } from '@atomicleads/ext-satellite-form';
import { PostHog } from 'posthog-js';

export const GTMPageView = (url: string, posthog: PostHog): void => {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'pageview',
      page: url,
      funnel: getFunnel(posthog)
    });
};
